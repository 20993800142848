const mainMass = [];
const thumbsMass = [];
const cardMass = [];

var interleaveOffset = 0.5,
    mainSlider,
    mainThumbs,
    cardSlider;

function marginSlider() {
    $(".main-slider .gallery-wrap").each(function () {
        let slider = $(this),
            width = $(window).innerWidth() - $(".main-slider .thumbs-wrap .container").offset().left + 1;
        if (window.innerWidth > 1439) {
            slider.css({
                width: width
            });
        } else {
            slider.removeAttr("style")
        }
    });
}

$(document).ready(function () {
    if ($(".main-slider .gallery-wrap").length) {
        marginSlider();
        $(window).resize(function () {
            marginSlider()
        });
    }

    $(".main-slider").each(function (index, element) {
        let $this = $(this);
        mainThumbs = new Swiper($this.find('.main-thumbs')[0], {
            spaceBetween: 0,
            slidesPerView: 1,
            loop: true,
            autoHeight: true,
            effect: 'fade',
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true
            }
        });
        thumbsMass.push(mainThumbs);

        mainSlider = new Swiper($this.find('.main-gallery')[0], {
            loop: true,
            speed: 1000,
            spaceBetween: 0,
            // grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            // keyboardControl: true,
            lazyLoading: true,
            lazy: {
                preloadImages: true,
                loadPrevNext: true,
                loadPrevNextAmount: 3,
                elementClass: "lazy"
            },
            navigation: {
                nextEl: $this.closest(".main-slider").find(".next-slide")[0],
                prevEl: $this.closest(".main-slider").find(".prev-slide")[0],
                disabledClass: 'disabled'
            },
            thumbs: {
                swiper: thumbsMass[index],
            },
            pagination: {
                el: $this.closest(".main-slider").find(".pagination")[0],
                type: 'fraction',
                currentClass: 'pagination-current',
                totalClass: 'pagination-total',
                renderFraction: function (currentClass, totalClass) {
                    return '<p><span class="' + currentClass + '"></span><span class="pagination-line"></span><span class="' + totalClass + '"></span></p>';
                }
            },
            on: {
                progress: function () {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector(".slide-wrap").style.transform =
                            "translate3d(" + innerTranslate + "px, 0, 0)";
                    }
                },
                touchStart: function () {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                },
                setTransition: function (speed) {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        swiper.slides[i].querySelector(".slide-wrap").style.transition =
                            speed + "ms";
                    }
                }
            }
        });
        mainMass.push(mainSlider)
    });

    $(".card-slider .swiper-container").each(function (index, element) {
        let $this = $(this);
        cardSlider = new Swiper($this, {
            slidesPerView: 2,
            spaceBetween: 100,
            grabCursor: true,
            loop: true,
            lazyLoading: true,
            lazy: {
                preloadImages: true,
                loadPrevNext: true,
                loadPrevNextAmount: 3,
                elementClass: "lazy"
            },
            navigation: {
                nextEl: $this.closest(".card-slider").find(".next-slide")[0],
                prevEl: $this.closest(".card-slider").find(".prev-slide")[0],
                disabledClass: 'disabled'
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                630: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                800: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                993: {
                    slidesPerView: 3,
                    spaceBetween: 80,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 100,
                },
            }
        });
        cardMass.push(cardSlider)
    });

    /*document.querySelector('[data-toggle]').addEventListener('click', function(){
      if (swiper.realIndex == 0) {
        swiper.slideTo(swiper.slides.length - 1);
      } else {
        swiper.slideTo(0);
      }
    });

    function logIndex () {
      requestAnimationFrame(logIndex);
      console.log(swiper.realIndex);
    }
    logIndex();*/

});